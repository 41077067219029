<template>
  <div class="callback-fixed">
    <a class="js-popup js-request" data-mfp-decor="mfp-product" href="#popup-request" @click="onOpenRequestCallModal">
      <img alt="Заказать обратный звонок" src="/img/obratny-zvonok.png">
    </a>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CallbackButton',

  methods: {
    ...mapMutations({
      setRequestCallAnalyticType: 'setRequestCallAnalyticType'
    }),

    onOpenRequestCallModal () {
      this.setRequestCallAnalyticType('callback_from_widget')
    }
  }
}
</script>

<style lang="scss">
.callback-fixed {
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 999;

  img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1007px) {
  .callback-fixed {
    position: fixed;
    right: 15px;
    bottom: 10px;

    img {
      width: 75px;
      height: 75px;
    }
  }
}

@media (hover: none) and (pointer: coarse) {
  .callback-fixed {
    position: fixed;
    right: 15px;
    bottom: 91px;

    img {
      width: 75px;
      height: 75px;
    }
  }
}
</style>
