<template>
  <footer class="footer">
    <div class="ancillary">
      <nuxt-link to="/">
        <div class="ancillary_logo footer-logo">
          <span>Love Forever</span>
        </div>
      </nuxt-link>
      <div class="ancillary_items">
        <!--<div class="ancillary_item ancillary_link">
          <nuxt-link to="/about/vacancies.php"
            >Вакансии</nuxt-link
          >
        </div>-->
        <div class="ancillary_item ancillary_link">
          <nuxt-link to="/articles/">
            Статьи
          </nuxt-link>
        </div>
        <div class="ancillary_item ancillary_link">
          <nuxt-link to="/reviews/">
            Отзывы
          </nuxt-link>
        </div>
        <div class="ancillary_item ancillary_link">
          <nuxt-link to="/contacts/">
            О салоне
          </nuxt-link>
        </div>
        <div class="ancillary_item ancillary_link">
          <nuxt-link to="/delivery/">
            Доставка и оплата
          </nuxt-link>
        </div>
        <div class="ancillary_item ancillary_link">
          <nuxt-link to="/sitemap/">
            Карта сайта
          </nuxt-link>
        </div>
        <li class="ancillary_item ancillary_link">
          <i class="icon icon__pin" />
          <nuxt-link to="/contacts/#contacts" style="display: inline-block;">
            Адрес
          </nuxt-link>
        </li>
        <div class="ancillary_item ancillary_fav is-active">
          <nuxt-link v-if="favoriteItems.length" :to="favoriteLink">
            <i class="icon icon__inline icon__fav-active" /><span>Любимое ({{ favoriteItems.length }})</span>
          </nuxt-link>
          <a
            v-else
            class="js-popup"
            data-mfp-decor="mfp-product"
            href="#popup-fav"
          ><i class="icon icon__inline icon__fav-active" /><span>Любимое (0)</span></a>
        </div>
        <!--<form action="/search/" method="get" class="ancillary_item nav_search">
          <input name="query" placeholder="поиск" type="text" />
          <button type="submit">Искать</button>
        </form>-->
      </div>
    </div>
    <div class="footer_details">
      <ul class="footer_social">
        <li class="footer_social_vk is_static">
          <a href="http://vk.com/loveforeversalon" target="_blank" rel="nofollow" />
        </li>
        <li class="footer_social_tg is_static">
          <a href="https://t.me/SalonLoveForever" target="_blank" rel="nofollow" />
        </li>
      </ul>
      <ul class="footer_payment">
        <li class="footer_payment_visa">
          Visa
        </li>
        <li class="footer_payment_mastercard">
          MasterCard
        </li>
        <li class="footer_payment_text">
          Мы принимаем<br>банковские карты
        </li>
      </ul>
      <address class="footer_address">
        <a href="mailto:hello@salon-love.ru">hello@salon-love.ru</a>
      </address>
    </div>
    <div class="inten">
      <a href="https://www.in10.top" rel="nofollow">Сделано в студии "Inten"</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SalonFooter',
  computed: {
    favoriteLink () {
      return `/favorites/?id=${this.favoriteItems.join('&id=')}`
    },
    favoriteItems () {
      return this.$store.state.favorite.items
    }
  }
}
</script>

<style></style>
