<template>
  <page-wrapper :decor="false">
    <section class="teaser teaser__poster">
      <div class="teaser_poster teaser_poster__wedding">
        <div class="teaser_poster_inner">
          <h1 style="margin-bottom: 40px;">
            Свадебный салон
          </h1>
          <nuxt-link
            to="/wedding/"
          >
            <ins>Более</ins> <strong>5000</strong> <span>Свадебных<br>платьев</span>
          </nuxt-link>
        </div>
      </div>
      <div class="teaser_corner" />
    </section>

    <section class="teaser teaser__facts">
      <div class="teaser_inner teaser_facts">
        <ul class="teaser_facts_slides">
          <li class="is-active" style="background: #200323;">
            <img alt="" src="/img/fact-1-min.jpg">
          </li>
          <li style="background: #120318;">
            <img alt="" src="/img/fact-2-min.jpg">
          </li>
          <li style="background: #0a0d15;">
            <img alt="" src="/img/fact-3-min.jpg">
          </li>
          <li style="background: #0a0114;">
            <img alt="" src="/img/fact-5-min.jpg">
          </li>
        </ul>
        <ol class="teaser_facts_pager">
          <li class="is-active">
            1
          </li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
        </ol>
        <div class="teaser_facts_title">
          Факт <span>1</span>
        </div>
        <ul class="teaser_facts_captions">
          <li class="is-active">
            <h3>
              Удобные лаундж-зоны для ожидающих вас гостей
            </h3>
          </li>
          <li>
            <h3>
              В наших салонах бесплатные примерки
            </h3>
          </li>
          <li>
            <h3>У нас большие зеркала и настоящий<br>подиум</h3>
          </li>
          <li>
            <h3>Накопительная скидочная карта при покупке от 20 000<i class="fa fa-rub" aria-hidden="true" /></h3>
          </li>
        </ul>
        <div class="teaser_facts_badge" />
      </div>
    </section>

    <section class="teaser teaser__wedding">
      <div class="teaser_corner" />
      <div class="decor decor__wedding" />
      <div class="teaser_inner">
        <div class="teaser_subtitle teaser_subtitle__decor">
          Подборка дня
        </div>

        <h2 class="teaser_title">
          Свадебные платья<span class="teaser_loader" @click="refreshWeddingDayItems" />
        </h2>
        <div class="teaser_hint is-active" />
        <ul class="teaser_slider">
          <li v-if="weddingItemsOfDay.length" v-swiper:weddingSwiper="swiperOption" class="teaser_slide is-active">
            <ul class="catalog_list__single swiper-wrapper">
              <li
                v-for="item in weddingItemsOfDay"
                :key="item.id"
                class="catalog_item catalog_item__teaser swiper-slide"
              >
                <nuxt-link :to="`/wedding/${item.brand.slug}/${item.slug}/`" class="catalog_figure">
                  <img
                    v-if="item.photo_day"
                    :alt="item.name"
                    :src="`${imageURL}/uploads/day_item/173-173/${item.photo_day.hash}${item.photo_day.ext}`"
                  >
                </nuxt-link>
                <h3 class="catalog_title">
                  {{ item.name }}
                </h3>
                <em
                  v-if="item.styles.length"
                  class="catalog_meta"
                ><nuxt-link :to="`/wedding/?style=${item.styles[0].id}`">{{ item.styles[0].name }}</nuxt-link></em>
                <noindex>
                  <button
                    :class="favoriteButtonClasses(item.id)"
                    class="catalog_fav"
                    title="Добавить в «Любимое», чтобы посмотреть позже"
                    @click="toggleFavorite(item.id)"
                  >
                    Нравится
                  </button>
                </noindex>
              </li>
            </ul>
          </li>
        </ul>

        <div class="teaser_more">
          <nuxt-link to="/wedding/">
            Смотреть полный каталог<br>свадебных платьев
          </nuxt-link>
        </div>
      </div>
    </section>
    <section class="teaser teaser__evening">
      <div class="teaser_corner" />
      <div class="decor decor__evening" />
      <div class="teaser_inner">
        <div class="teaser_subtitle teaser_subtitle__decor">
          Подборка дня
        </div>
        <h2 class="teaser_title">
          Вечерние платья<span class="teaser_loader" @click="refreshEveningDayItems" />
        </h2>

        <ul class="teaser_slider">
          <li v-if="eveningItemsOfDay.length" v-swiper:eveningSwiper="swiperOption" class="teaser_slide is-active">
            <ul class="catalog_list__single swiper-wrapper">
              <li
                v-for="item in eveningItemsOfDay"
                :key="item.id"
                class="catalog_item catalog_item__teaser swiper-slide"
              >
                <nuxt-link :to="`/evening/${item.brand.slug}/${item.slug}/`" class="catalog_figure">
                  <img
                    v-if="item.photo_day"
                    :alt="item.name"
                    :src="`${imageURL}/uploads/day_item/173-173/${item.photo_day.hash}${item.photo_day.ext}`"
                  >
                </nuxt-link>
                <h3 class="catalog_title">
                  {{ item.name }}
                </h3>
                <em
                  v-if="item.styles.length"
                  class="catalog_meta"
                ><nuxt-link :to="`/wedding/?style=${item.styles[0].id}`">{{ item.styles[0].name }}</nuxt-link></em>
                <noindex>
                  <button
                    :class="favoriteButtonClasses(item.id)"
                    class="catalog_fav"
                    title="Добавить в «Любимое», чтобы посмотреть позже"
                    @click="toggleFavorite(item.id)"
                  >
                    Нравится
                  </button>
                </noindex>
              </li>
            </ul>
          </li>
        </ul>

        <div class="teaser_more">
          <nuxt-link to="/evening/">
            Смотреть полный каталог<br>вечерних платьев
          </nuxt-link>
        </div>
      </div>
      <div class="teaser_corner__eveningEnd" />
    </section>

    <articles-home v-if="articles" :articles="articles" />
    <!--<input
      type="hidden"
      name="social_share_title_1"
      value="Дом свадебной и вечерней моды Love Forever"
    />
    <input
      type="hidden"
      name="social_share_description_1"
      value="Более 3 000 платьев на самый искушенный вкус!"
    />
    <input
      type="hidden"
      name="social_share_url_1"
      value="https://www.salon-love-forever.ru/"
    />
    <input
      type="hidden"
      name="social_share_image_1"
      value="/img/logo_for_sharing.jpg"
    />-->

    <div class="wrapper teaser-wrapper">
      <!-- <div class="teaser_corner" /> -->
      <section class="wrapper_text">
        <!--<ul class="social social__note">
          <li class="social_fb" rel="1">
            <a href="#"><span>0</span></a>
          </li>
          <li class="social_vk" rel="1">
            <a href="#"><span>0</span></a>
          </li>
          <li class="social_tw" rel="1">
            <a href="#"><span>0</span></a>
          </li>
        </ul>-->
        <h2>
          Ждем вас в нашем салоне
        </h2>
        <h6>
          <span class="icon icon__inline icon__time" />мы работаем ежедневно с 10 до 22 или до последнего клиента
        </h6>

        <LazyHydrate when-visible>
          <MapSection />
        </LazyHydrate>

        <ul class="wrapper_contacts">
          <li class="wrapper_contact">
            <strong class="wrapper_contact_title">ЯКИМАНКА</strong>
            м. Октябрьская<br>
            ул. Большая Якиманка 50 <br>
            (3 мин. от метро)<br>
            Ежедневно с 10:00 до 22:00<br>
            <address class="ancillary_item ancillary_phone">
              <span>Салон <a href="tel:84956451908">8 495 645 19 08</a></span>
              <br>
              <span>Ателье <a href="tel:89168204497">8 916 820 44 97</a></span>
            </address>
          </li>
          <li class="wrapper_contact wrapper_contact__phone">
            <strong
              class="wrapper_contact_title"
            ><a class="pseudo js-popup" data-mfp-decor="mfp-product" href="#popup-request">Заказать звонок</a></strong>Мы оперативно перезвоним вам сами!
          </li>
        </ul>
      </section>
      <section class="wrapper_text">
        <a
          href="https://app.halvacard.ru/order/?utm_medium=Partner&utm_source=60253177&utm_campaign=
halva"
          rel="nofollow"
        ><img
          src="/img/ad-block/halva/1_728x90.jpg"
        ></a>
      </section>
    </div>
    <div class="decor decor__2 css-sprite-2" />
    <div class="decor decor__5 css-sprite-5" />
    <div class="decor decor__7 css-sprite-7" />
    <div class="decor decor__8 css-sprite-8" />
    <div class="decor decor__10 css-sprite-10" />
    <div class="decor decor__12 css-sprite-12" />
    <div class="decor decor__13 css-sprite-13" />
    <div class="decor decor__14 css-sprite-14" />
    <div class="decor decor__15 css-sprite-15" />
    <div class="decor decor__16 css-sprite-16" />
    <div class="decor decor__17 css-sprite-17" />
    <div class="decor decor__18 css-sprite-18" />
    <div class="decor decor__19 css-sprite-19" />
    <div class="decor decor__20 css-sprite-20" />
    <div class="decor decor__21 css-sprite-21" />
    <div class="decor decor__22 css-sprite-22" />
    <div class="decor decor__23 css-sprite-23" />
  </page-wrapper>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import PageWrapper from '@/components/pages/PageWrapper'
import { app, initApp } from '@/assets/js/common'
import analyticsMixin from '@/assets/mixins/analytics.mixin'
import { CLIENT_URL, IMAGE_URL } from '@/assets/js/constants'
import ArticlesHome from '@/components/layout/ArticlesHome.vue'

const pageClasses = 'page-loveforever page-vacuum page-front is-loaded'

export default {
  components: { MapSection: () => import('./../components/MapSection.vue'), PageWrapper, ArticlesHome, LazyHydrate },
  mixins: [analyticsMixin],
  async asyncData ({ store, $axios }) {
    const articles = await $axios.$get('/articles?active=1&_sort=force_update_at:ASC,id:DESC&_limit=5')
    let weddingItemsOfDay = await store.dispatch('loadMainPageItems', 'wedding')
    weddingItemsOfDay = weddingItemsOfDay.map((item) => {
      return { ...item, description: null, styles: [], categories: [], articles }
    })
    let eveningItemsOfDay = await store.dispatch('loadMainPageItems', 'evening')
    eveningItemsOfDay = eveningItemsOfDay.map((item) => {
      return { ...item, description: null, styles: [], categories: [], articles }
    })

    const seoResult = store.state.seoEntities.find(item => item.type === 'mainpage')

    return {
      weddingItemsOfDay,
      eveningItemsOfDay,
      seo: seoResult ? seoResult.seo[0] : null,
      articles
    }
  },
  data: () => {
    return {
      imageURL: IMAGE_URL,
      weddingStyleOption: 'all',
      weddingBrandOption: 'all',
      eveningStyleOption: 'all',
      eveningBrandOption: 'all',
      swiperOption: {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 140,
        roundLengths: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          767: {
            spaceBetween: 50
          },
          // when window width is <= 640px
          1024: {
            spaceBetween: 100
          }
        }
      }
    }
  },
  computed: {
    favoriteButtonClasses () {
      return (id) => {
        return {
          'is-active heartBeat animated': this.favoriteItems.includes(id)
        }
      }
    },
    favoriteItems () {
      return this.$store.state.favorite.items
    },
    canonicalUrl () {
      return `${CLIENT_URL}/`
    }
  },
  mounted () {
    if (app.prepared) {
      setTimeout(() => {
        initApp(pageClasses, this.$store)
      }, 0)
    }
  },
  methods: {
    async refreshWeddingDayItems () {
      this.weddingItemsOfDay = []
      this.weddingItemsOfDay = await this.$store.dispatch('loadMainPageItems', 'wedding')
      this.weddingItemsOfDay = this.weddingItemsOfDay.map((item) => {
        return { ...item, description: null, styles: [], categories: [] }
      })
    },
    async refreshEveningDayItems () {
      this.eveningItemsOfDay = []
      this.eveningItemsOfDay = await this.$store.dispatch('loadMainPageItems', 'evening')
      this.eveningItemsOfDay = this.eveningItemsOfDay.map((item) => {
        return { ...item, description: null, styles: [], categories: [] }
      })
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: pageClasses
      },
      title: this.seo.title,
      meta: [{ name: 'description', content: this.seo.description }]
    }
  }
}
</script>

<style lang="scss"></style>
