<template>
  <div class="layout_inner">
    <salon-header />
    <slot />
    <client-only>
      <template v-if="decor">
        <div class="decor decor__2 css-sprite-2" />
        <div class="decor decor__3 css-sprite-3" />
        <div class="decor decor__4 css-sprite-4" />
        <div class="decor decor__5 css-sprite-5" />
        <div class="decor decor__6 css-sprite-6" />
        <div class="decor decor__7 css-sprite-7" />
        <div class="decor decor__8 css-sprite-8" />
        <div class="decor decor__9 css-sprite-9" />
        <div class="decor decor__10 css-sprite-10" />
      </template>
    </client-only>
    <salon-footer />
    <client-only>
      <bottom-counters />
      <callback-button />
    </client-only>
    <organization-snippet />
    <web-page-snippet />
    <web-site-snippet />
  </div>
</template>

<script>
import BottomCounters from '../layout/BottomCounters'
import SalonFooter from '../layout/SalonFooter'
import SalonHeader from '../layout/SalonHeader'
import OrganizationSnippet from '../ld-json/OrganizationSnippet'
/* import WebPageSnippet from '../ld-json/WebPageSnippet'
import WebSiteSnippet from '../ld-json/WebSiteSnippet' */
import CallbackButton from '../controls/CallbackButton'

export default {
  name: 'PageWrapper',
  components: {
    CallbackButton,
    SalonHeader,
    SalonFooter,
    BottomCounters,
    OrganizationSnippet
  },
  props: {
    decor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    bgOverlay () {
      return this.$store.state.bgOverlay
    },
    favoriteLink () {
      return `/favorites/?id=${this.favoriteItems.join('&id=')}`
    },
    favoriteItems () {
      return this.$store.state.favorite.items
    },
    hasFavorite () {
      return this.favoriteItems.length > 0
    }
  },
  head () {
    const scripts = []
    const links = []

    if (this.$store.state.debugWeinreId) {
      scripts.push({
        type: 'text/javascript',
        src: `https://dbg.salon-love-forever.ru/target/target-script-min.js#${this.$store.state.debugWeinreId}`
      })
    }

    links.push(
      {
        rel: 'dns-prefetch',
        href: '//mc.yandex.ru'
      },
      {
        rel: 'dns-prefetch',
        href: '//www.google.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//www.gstatic.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//fonts.gstatic.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//code.jivosite.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//telemetry.jivosite.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//www.google-analytics.com'
      }
    )

    return {
      script: scripts,
      link: links
    }
  }
}
</script>

<style lang="scss"></style>
