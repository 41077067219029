<template>
  <section v-if="articles" class="teaser teaser__articlesHome">
    <div class="teaser__articlesHome__container">
      <h2 class="teaser__articlesHome__title">
        СТАТЬИ
      </h2>
      <div class="teaser__articlesHome__block">
        <img class="teaser__articlesHome__logoDesignImage" src="/img/background-logo-1-.png">
        <div v-for="(item, index) in articles" :key="index" class="teaser__articlesHome__itemBlock">
          <nuxt-link :to="`/articles/${item.slug}/`" class="teaser__articlesHome__imageButton">
            <img
              v-if="item.images[0]"
              :src="`${imageURL}/uploads/articles/${item.images[0].hash}${item.images[0].ext}`"
              alt=""
              class="teaser__articlesHome__image"
            >
          </nuxt-link>
          <div class="teaser__articlesHome__imageDisignBlock" />
          <div class="teaser__articlesHome__textBlock">
            <nuxt-link :to="`/articles/${item.slug}/`" class="teaser__articlesHome__textTitleButton">
              <h3 class="teaser__articlesHome__textTitle">
                {{ item.title }}
              </h3>
            </nuxt-link>
            <p class="teaser__articlesHome__textDescription">
              {{ item.intro }}
            </p>
          </div>
        </div>
      </div>
      <nuxt-link :to="`/articles/`" class="teaser__articlesHome__buttonMore">
        СМОТРЕТЬ ВСЕ СТАТЬИ
      </nuxt-link>
    </div>
  </section>
</template>
<script>
import { IMAGE_URL } from '@/assets/js/constants'
export default {
  name: 'ArticlesHome',
  props: {
    articles: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      imageURL: IMAGE_URL
    }
  }
}
</script>

<style lang="scss" scoped>
    .teaser__articlesHome{
        padding: 13px 0 0;
        &::before{
            background-color: #f5f5f5;
            z-index: -1;
            background-image: url('/img/background-flower-rose.png');
            background-repeat: no-repeat;
            background-position: 80% -200px;
            left: -820px;
            right: -820px;
            @media screen and (max-width:767.9px) {
                background-position: 108% -200px;
            }
        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -820px;
            right: -820px;
            z-index: -1;
            background-image: url('/img/background-flower-white.png');
            background-repeat: no-repeat;
            background-position: 20% 100%;
            left: -820px;
            right: -820px;
            @media screen and (max-width:767.9px) {
                background-position: -8% 100%;
            }
        }
        &__container{
            margin: 120px 0 0 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width:1745px) {
                margin: 170px 0 0 0;
            }
        }
        &__title{
            font: bold 36px 'ProxyB', Arial, Helvetica, sans-serif;
            color: #130214;
            letter-spacing: 7px;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 60px 0;
            @media screen and (max-width:1020px) {
                font-size: 20px;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 0 100px 0;
            @media screen and (max-width:1100px) {
                margin: 0 0 80px 0;
            }
            @media screen and (max-width:767.9px) {
                margin: 0 0 50px 0;
            }

        }
        &__itemBlock{
            max-width: 385px;
            min-height: 500px;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 0 0 0 40px;
            @media screen and (max-width:1100px) {
                max-width: 340px;
                margin: 0 0 0 40px;
            }
            @media screen and (max-width:799px) {
                margin: 0 0 0 20px;
            }
            @media screen and (max-width:767.9px){
                align-self: center;
                margin: 0 0 50px 0;
            }
            @media screen and (max-width: 425px){
                margin: 0 40px 50px 40px;
                width: auto;
            }
            &:nth-of-type(2){
                align-self: end;
                margin: -235px 40px 165px 0;
                .teaser__articlesHome__textBlock{
                    left: -425px;
                    bottom: 0;
                    top: auto;
                    @media screen and (max-width:1100px) {
                        left: -300px;
                    }
                }
                .teaser__articlesHome__imageDisignBlock{
                    left: auto;
                    right: -20px;
                    background-color: #DED2BC;
                }
                @media screen and (max-width:1100px) {
                    margin: -235px 40px 185px 0;
                }
                @media screen and (max-width:799px) {
                    margin: -235px 20px 185px 0;
                }
                @media screen and (max-width:767.9px){
                        align-self: center;
                        margin: 0 0 50px 0;
                }
                @media screen and (max-width: 425px){
                    margin: 0 40px 50px 40px;
                }
            }

            &:nth-of-type(3){
                align-self: center;
                justify-content: center;
                margin: 0 0 165px 0;
                .teaser__articlesHome__textBlock{
                    top: auto;
                    @media screen and (max-width:1100px) {
                        left: 380px;
                    }
                }
                .teaser__articlesHome__imageDisignBlock{
                    background-color: #B1B8B6;
                }
                @media screen and (max-width:1006px) {
                    left: -100px;
                    margin: 0 0 185px 0;
                }
                @media screen and (max-width:767.9px){
                    align-self: center;
                    margin: 0 0 50px 0;
                    left: auto;
                }
                @media screen and (max-width: 425px){
                    margin: 0 40px 50px 40px;
                }
            }

            &:nth-of-type(5){
                align-self: end;
                margin: -235px 40px 0 0;
                .teaser__articlesHome__textBlock{
                    left: -425px;
                    bottom: 0;
                    top: auto;
                    @media screen and (max-width:1100px) {
                        left: -300px;
                    }
                }
                .teaser__articlesHome__imageDisignBlock{
                    left: auto;
                    right: -20px;
                    background-color: #DED2BC;
                }
                @media screen and (max-width:1100px) {
                    margin: -235px 40px 0 0;
                }
                @media screen and (max-width:799px) {
                    margin: -235px 20px 0 0;
                }
                @media screen and (max-width:767.9px){
                        align-self: center;
                        margin: 0;
                }
                @media screen and (max-width: 425px){
                    margin: 0 40px 0 40px;
                }
            }

        }
        &__imageDisignBlock{
            width: 270px;
            height: 270px;
            background-color: #E4DFDB;
            position: absolute;
            top: -20px;
            z-index: 1;
            left: -20px;
            transition: all 0.2s ease ;
        }
        &__image{
            max-width: 385px;
            width: 100%;
            z-index: 2;
            @media screen and (max-width:1100px) {
                max-width: 340px;
            }

            @media screen and (max-width: 767.9px){
                margin: 0 0 20px 0;
            }

        }
        &__textBlock{
            top: 0;
            left: 425px;
            max-width: 362px;
            width: 100%;
            position: absolute;
            @media screen and (max-width:1250px) {
                max-width: 273px;
            }
            @media screen and (max-width: 767.9px){
                position: static;
                max-width: 340px;
            }

        }
        &__textTitle{
            font: bold 18px 'ProxyB', Arial, Helvetica, sans-serif;
            color: #471A4A;
            margin: 0 0 15px 0;
            transition: all 0.2s ease ;
            line-height: 22px;
            @media screen and (max-width:1100px) {
                font-size: 16px;
            }
            @media screen and (max-width: 767.9px){
                margin: 0 0 10px 0;
            }
        }
        &__textDescription{
            font: normal 14px 'ProxyL', Arial, Helvetica, sans-serif;
            line-height: 17px;
            color: #575757;
        }
        &__buttonMore{
            text-decoration: none;
            max-width: 220px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #471A4A;
            font: bold 14px 'ProxyB', Arial, Helvetica, sans-serif;
            background-color: #fff;
            transition: all 0.3s ease;
            &:hover{
                background-color: #471A4A;
                color: #FFFFFF;
            }
        }
        &__logoDesignImage{
            position: absolute;
            width: 110px;
            height: 545px;
            bottom: 50%;
            object-fit: cover;
            left: 40px;
            @media screen and (max-width:1100px) {
                left: 8%;
            }
            @media screen and (max-width: 940px) {
                left: 0;
            }
            @media screen and (max-width: 767.9px) {
                display: none;
            }
        }
        &__imageButton{
            z-index: 2;
            text-decoration: none;
        &:hover + .teaser__articlesHome__imageDisignBlock{
            background-color: #833388;
        }
        }
        &__textTitleButton{
            text-decoration: none;
            &:hover .teaser__articlesHome__textTitle{
                color: #833388;
            }
        }
    }

</style>
