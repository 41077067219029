import { render, staticRenderFns } from "./ArticlesHome.vue?vue&type=template&id=07dc7252&scoped=true&"
import script from "./ArticlesHome.vue?vue&type=script&lang=js&"
export * from "./ArticlesHome.vue?vue&type=script&lang=js&"
import style0 from "./ArticlesHome.vue?vue&type=style&index=0&id=07dc7252&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07dc7252",
  null
  
)

export default component.exports