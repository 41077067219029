<template>
  <span />
</template>
<script>
export default {
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      address: [
        {
          '@type': 'PostalAddress',
          addressLocality: 'Россия, Москва',
          streetAddress: 'ул. Большая Якиманка д.50'
        }
      ],
      name: 'Свадебный салон Love Forever',
      image: '/img/logos/logo.jpg',
      telephone: '+7 (495) 645-19-08',
      url: 'https://www.salon-love-forever.ru/',
      sameAs: [
        'http://vk.com/loveforeversalon',
        'https://t.me/SalonLoveForever'
      ]
    }
  }
}
</script>
